<template>
  <div>检验项目维护---待开发</div>
</template>

<script>
export default {
  name: "TestCheckCategory"
}
</script>

<style scoped>

</style>